import { Controller } from "@hotwired/stimulus"
import VanillaCalendar from 'vanilla-calendar-pro';
import 'vanilla-calendar-pro/build/vanilla-calendar.min.css';

// Connects to data-controller="date-range"
export default class extends Controller {

    static values = {
        startDate: String,
        endDate: String
    }
  connect() {

      const options = {
          input: true,
          settings: {
              selection: {
                  day: 'multiple-ranged',
              },
              selected: {
                  dates: [this.element.value], // Preselect start and end date of the range
              },
          },
          actions: {
              changeToInput(e, self) {
                  if (!self.HTMLInputElement) return;
                  if (self.selectedDates[1]) {
                      self.selectedDates.sort((a, b) => +new Date(a) - +new Date(b));
                      self.HTMLInputElement.value = `${self.selectedDates[0]} to ${self.selectedDates[self.selectedDates.length - 1]}`;
                  } else if (self.selectedDates[0]) {
                      self.HTMLInputElement.value = self.selectedDates[0];
                  } else {
                      self.HTMLInputElement.value = '';
                  }
                  if (Materialize) {
                      Materialize.updateTextFields;
                  }
              },
          },
      };
      const calendarInput = new VanillaCalendar(this.element, options);
      calendarInput.init();

      console.log("This value: ", this.element.value);
  }
}
