/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery"
import jQuery from "jquery"
global.jQuery = jQuery

import "@hotwired/turbo-rails"

import { initTE, Input, Modal, Select, Tab } from "tw-elements";

import "trix";
import "@rails/actiontext";
import "packs/richtext";

import '../styles/application.scss';
import '../bundles/migrated/application'
import "controllers";

document.addEventListener("DOMContentLoaded", () => {
    initialize()
})

document.addEventListener("turbo:load", () => {
    initialize()
})

document.addEventListener("turbo:frame-load", (event) => {
    if (event.target.id === "documents_view") {
        initialize();
    }
});


Chartkick.config.autoDestroy = false

window.addEventListener('turbo:before-render', () => {
    Chartkick.eachChart(chart => {
        if (!chart.element.isConnected) {
            chart.destroy()
            delete Chartkick.charts[chart.element.id]
        }
    })
})

function initialize() {
    initTE({Input, Modal, Select, Tab});
}


