import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["next_action","service_type_fields"];

    connect() {
        // Initially service type should be hidden
        this.handleNextActionChange();

        $(this.next_actionTarget).on('change', (e) => {
            this.handleNextActionChange();
        });
    }

    handleNextActionChange() {
        const next_action = $(this.next_actionTarget).val();
        const elem = $(this.service_type_fieldsTarget);

        if ((next_action === 'appointment') || (next_action === 'retention_followup')) {
            elem.find('input,select').prop('disabled', false);
            elem.find($('select')).material_select();
            elem.show();
        }
        else {
            elem.find('input,select').prop('disabled', true);
            elem.hide();
        }
    }
}
