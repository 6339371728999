import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["branch", "warehouse", "warehouse_note"];
  static values = { company: Array};

  connect() {
    console.log("Branch select Controller");

    this.updateWarehouses($(this.warehouseTarget), $(this.branchTarget).val(), this.companyValue);
    $(this.branchTarget).on('change', (e) => {
      this.updateWarehouses($(this.warehouseTarget), $(this.branchTarget).val(), this.companyValue);
    });
  }

    updateWarehouses(warehouse_elem, state_name, company) {
      if(state_name === undefined || state_name === null || state_name === ''){
          warehouse_elem.empty();
          warehouse_elem.material_select();
          return;
      }

      fetch('/states/' + state_name + "/branch_warehouses/"+ company +".json", {
          method: 'GET',
          credentials: "same-origin"
      }).then(
          (response) => response.json()
      ).then((json) => {
          warehouse_elem.empty();
          const {warehouse_codes} = json;

          let selected_value = warehouse_elem.val();
          console.log(selected_value);

          selected_value = selected_value.some(value => warehouse_codes.includes(value)) ?
              selected_value : [];

          if (warehouse_codes === undefined || warehouse_codes.length === 0){
              $(this.warehouse_noteTarget).show();
          }else{
              warehouse_codes.forEach((option) => {
                  const selected = selected_value.length === 0 ? true : !!selected_value.includes(option);
                  const o = new Option(option.split('')
                      .map(function (s) {
                          return (s.charAt(0).toUpperCase() + s.slice(1));
                      })
                      .join(' '), option, selected, selected);
                  warehouse_elem.append($(o));
              });
          }
          warehouse_elem.material_select();
      }).catch((err) => console.log(err));
    }
}
